import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate, useLocation } from "react-router-dom";
import { emptyCart, otherRelevantQueries, addToCartFromOtherRelavant } from '../../Slices/askQuerySlice';
import { addFilter, removeFilter, clearAllFilters } from '../../Slices/filterSlice';
import {
    addPersonalisedFilter,
    removePersonalisedFilter,
    clearPersonalisedFilter,
    clearAllPersonalisedFilters
} from '../../Slices/personaliseFilterSlice';
import { useMediaQuery, CircularProgress } from '@mui/material';
import "./home.css"
import { baseUrl } from "../../utils/baseUrl";
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import { updateAssistance, encodeFiltersToQueryString, decodeQueryStringToFilters } from '../../utils/query'
import QueryPageMobileMenu from "./QueryPageMobileMenu";
import SideBarLayout from "./SideBarLayout";
import MobileSidebarLayout from "./MobileSidebarLayout";

const SixthQuery = ({ onGoBack, step }) => {
    const [isCollapse, setIsCollapse] = useState({ [0]: true });
    const [topFilteredQuery, setTopFilteredQuery] = useState([]);
    const [selectedCardId, setSelectedCardId] = useState(null);
    const [openCategory, setOpenCategory] = useState(false);
    const [searchText, setSearchText] = useState();
    const filters = useSelector((state) => state.selectFilters.filters);

    const personalisedFilters = useSelector((state) => state.selectPersonalisedFilters.personalisedFilters);

    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [initialFilters, setInitialFilters] = useState({});

    // const { cart } = useSelector((state) => state.askQuery);
    const initialCart = sessionStorage.getItem('cart') ? JSON.parse(sessionStorage.getItem('cart')) : [];
    const [cart, setCart] = useState(initialCart)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [exploreMore, setExploreMore] = useState(false);
    const [activeSection, setActiveSection] = useState('answer');
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const sectionRef = useRef(null);

    const fetchFilteredData = async () => {
        setIsLoading(true)
        try {
            // console.log("personalisedFilters",personalisedFilters)
            const checkIfpersonalisedFiltersApplied = personalisedFilters.category.length === 0 && personalisedFilters.goals.length === 0 && personalisedFilters.profession.length === 0 && personalisedFilters.financialStatus.length === 0 && personalisedFilters.experience.length === 0 && personalisedFilters.personality.length === 0
            if (!checkIfpersonalisedFiltersApplied) {
                dispatch(clearAllPersonalisedFilters());
            }

            const queryString = encodeFiltersToQueryString(!checkIfpersonalisedFiltersApplied ? personalisedFilters : filters, searchText, initialFilters);
            const response = await fetch(`${baseUrl}/search-page-query/search-query?${queryString}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setTopFilteredQuery(data?.queries);
            setIsLoading(false)

        } catch (error) {
            console.error('Error fetching filtered data:', error);
            setIsLoading(false)
        }
    };

    useEffect(() => {
        const visitedThrough = window.localStorage.getItem("visitedThrough")
        if (visitedThrough === "/3") {
            const timer = setTimeout(() => {
                setIsInitialLoading(false);
                fetchFilteredData()
                window.localStorage.removeItem("visitedThrough")
            }, 4000);
            return () => clearTimeout(timer);
        } else {
            setIsInitialLoading(false);
        }

    }, []);

    const handleAddToCart = (query) => {
        const currentCart = sessionStorage.getItem('cart') ? JSON.parse(sessionStorage.getItem('cart')) : [];
        const isAlreadyInCart = currentCart.some((item) => item._id === query._id);

        let updatedCart;
        if (isAlreadyInCart) {
            updatedCart = currentCart.filter((item) => item._id !== query._id);
        } else {
            updatedCart = [...currentCart, query];
        }
        sessionStorage.setItem('cart', JSON.stringify(updatedCart));
        setCart(updatedCart)
    };


    const handleAutoMation = (query) => {
        const currentCart = sessionStorage.getItem('cart') ? JSON.parse(sessionStorage.getItem('cart')) : [];
        const isAlreadyInCart = currentCart.some((item) => item._id === query._id);
        if (!isAlreadyInCart) {
            const updatedCart = [...currentCart, query];
            sessionStorage.setItem('cart', JSON.stringify(updatedCart));
            setCart(updatedCart);
        } else {
            setCart(currentCart);
        }

        const otherQueries = topFilteredQuery.filter(query =>
            !cart.some(cartItem => cartItem._id === query._id)
        );

        sessionStorage.setItem('otherQueries', JSON.stringify(otherQueries));
        dispatch(otherRelevantQueries(otherQueries));

        const isValid = localStorage.getItem("token") && String(localStorage.getItem("isDetailFilled")) === "true";
        const isLoggedIn = isValid ? true : false;
        updateAssistance(cart);
        navigate(`/queries/${cart[0]?.url}`);

        // if (isLoggedIn) {
        //     updateAssistance(cart);
        //     navigate('/queries');
        // } else {
        //     navigate('/user/signup', { state: { redirectTo: "/queries" } });
        // }
    };

    const updateUrlWithFilters = (filters) => {
        const queryString = encodeFiltersToQueryString(filters, initialFilters);
        // console.log("personalisedFilters",personalisedFilters)

        const checkIfpersonalisedFiltersApplied = personalisedFilters.category.length === 0 && personalisedFilters.goals.length === 0 && personalisedFilters.profession.length === 0 && personalisedFilters.financialStatus.length === 0 && personalisedFilters.experience.length === 0 && personalisedFilters.personality.length === 0
        if (!checkIfpersonalisedFiltersApplied) {
            // console.log("path", `${location.pathname}?${queryString}`)
            return
        }
        navigate(`${location.pathname}?${queryString}`, { replace: true });
    };

    const itemsToShow = exploreMore ? topFilteredQuery : topFilteredQuery.slice(0, 6);

    const handleExploreMoreClick = () => {
        if (exploreMore && sectionRef.current) {
            sectionRef.current.scrollTo({ top: 0, behavior: 'smooth' });
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        setExploreMore((prev) => !prev);
    };

    const handleCollapse = (index) => {
        setIsCollapse((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleCloseCategory = () => {
        setOpenCategory(false);
    };

    const cartItemCount = cart.length;
    const isItemInCart = (cardId) => cart.some((item) => item._id === cardId);


    const handleGetAnswerClick = () => {
        if (cart.length === 0) {
            return alert("Please Select at least one query to get an answer");
        }
        const otherQueries = topFilteredQuery.filter(query =>
            !cart.some(cartItem => cartItem._id === query._id)
        );
        sessionStorage.setItem('otherQueries', JSON.stringify(otherQueries));
        dispatch(otherRelevantQueries(otherQueries));
        const isValid = localStorage.getItem("token") && String(localStorage.getItem("isDetailFilled")) === "true"
        const isLoggedIn = isValid ? true : false;
        updateAssistance(cart);
        const dynamicUrl=cart[0].url;
        navigate(`/queries/${dynamicUrl}`);

        // if (isLoggedIn) {
        //     updateAssistance(cart);
        //     navigate('/queries');
        // } else {
        //     navigate('/user/signup', { state: { redirectTo: "/queries" } });
        // }
    };

    useEffect(() => {
        setInitialFilters(filters);
        const initialFilters = decodeQueryStringToFilters(location.search);
        Object.keys(initialFilters).forEach((filterType) => {
            initialFilters[filterType].forEach((value) => {
                dispatch(addFilter({ filterType, value }));
            });
        });
    }, [dispatch, location.search]);


    useEffect(() => {

        updateUrlWithFilters(filters);
        if (searchText && searchText.length > 0) {
            const delayDebounceFn = setTimeout(() => {
                fetchFilteredData();
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        } else {
            fetchFilteredData();
        }
    }, [filters, navigate, searchText]);

    const handleGoBack = () => {
        const sourceURL = window.localStorage.getItem("visitedThrough") || "/3";
        dispatch(clearAllFilters());
        dispatch(emptyCart());
        localStorage.removeItem('cart');
        sessionStorage.removeItem('cart');
        sessionStorage.removeItem('otherQueries');
        onGoBack();
        navigate(sourceURL);
    };

    const handleFindForMe = () => {
        dispatch(clearAllFilters());
        dispatch(emptyCart());
        localStorage.removeItem('cart');
        sessionStorage.removeItem('cart');
        onGoBack();
        navigate("/3")
    }


    const handleBottomNavigationChange = (event, newValue) => {
        setActiveSection(newValue);
    };

    const handleFilter = () => {
        setOpenCategory(prev => !prev)
    }
    return (

        <div className="space-y-5 relative">

            {
                isInitialLoading ?
                    <div className="flex flex-col items-center justify-center min-h-[80vh]">
                        <iframe src="https://lottie.host/embed/e2ced16c-1a9d-45c2-9803-10cb2cd2eea4/wwPgLkYSMz.json"></iframe>
                        <h2 className="text-center mt-10">Personalising Queries For You</h2>
                    </div> :

                    <>

                        <div className="absolute top-[0] lg:top-[0] lg:left-10 flex items-center ml-2" onClick={handleGoBack}>
                            <IoMdArrowRoundBack className="cursor-pointer"></IoMdArrowRoundBack>
                        </div>
                        <div className="text-center max-w-[80vw] mx-auto min-h-[20px]">

                        </div>

                        {/* Search Bar */}
                        <div className="flex lg:max-w-[90%] mx-auto justify-center mb-6 py-[15px] items-center gap-x-[1em] sticky top-[6vh] md:top-[11vh] bg-[#eef8f6]">
                            <button
                                className="hidden lg:bg-[#89FBEA] lg:flex items-center h-[45px] lg:w-[30%] lg:rounded-lg max-w-[30%] flex-row justify-center gap-[5px] shadow-lg"
                                onClick={() => handleFindForMe()}
                            >
                                <span className="font-bold text-black lg:text-[1.125rem] text-[1rem]">Find Query</span>
                            </button>

                            {/* Search Input and Get Answer Button */}
                            <div className="flex items-center gap-x-[1em] md:w-[70%] w-[90%] h-[45px] rounded-l-lg">
                                <div className="relative flex justify-center lg:rounded-lg md:w-[70%] w-full h-[45px]">
                                    {/* Search Input */}
                                    <div className="flex-grow h-full hidden lg:flex">
                                        <input
                                            type="text"
                                            placeholder="Search Query"
                                            name="search"
                                            className="w-full lg:max-w-[75vw] h-[100%] focus:outline-none pl-4 pr-16 lg:rounded-lg border-1 border-[#dadada] text-[1rem] placeholder-gray-500"
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                        <div className="absolute right-0 top-0 flex items-center lg:w-[4rem] h-[45px] justify-center rounded-r-lg">
                                            <SearchIcon style={{ fontSize: "2rem" }} className="text-black" />
                                        </div>
                                    </div>

                                    {/* Small screen input */}
                                    <div className="bg-white flex lg:hidden items-center  rounded-lg pl-2 w-full h-[6vh]">
                                        <SearchIcon style={{ fontSize: "2rem" }} className="text-gray-400 font-medium h-[6vh]" />
                                        <input
                                            type="text"
                                            placeholder="Search Query"
                                            name="search"
                                            className="h-[6vh] border-1 border-[#dadada] focus:outline-none placeholder-gray-500 pl-2 w-full rounded-r-lg"
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />

                                    </div>
                                </div>

                                {/* Get Answer Button */}
                                {/* font-bold text-black lg:text-[1.125rem] text-[1rem] */}
                                <button
                                    className="md:w-[30%] hidden lg:flex items-center justify-center h-[45px] px-4 font-bold lg:text-[1.125rem] text-[1rem] text-white rounded-lg"
                                    style={{ backgroundColor: cartItemCount <= 0 ? "#dadada" : "rgb(4, 81, 114)", whiteSpace: "nowrap", padding: "5px 7px", borderRadius: "7px", color: "#fff" }}
                                    onClick={handleGetAnswerClick}
                                >
                                    <BookmarksOutlinedIcon className="mr-1" />
                                    My List
                                    <span className="get-answer-badge">{cartItemCount}</span>
                                </button>
                            </div>
                        </div>

                        <div className="flex w-[90%] mx-auto  justify-center mb-6 gap-x-[1em] md:h-[80vh]">
                            <SideBarLayout handleCollapse={handleCollapse} setSearchText={setSearchText} filters={filters} isCollapse={isCollapse} />
                            {/* Right Content */}
                            <div className=" lg:w-[70%] overflow-y:auto h-[100%]" ref={sectionRef} style={{
                                overflowY: "auto",
                                scrollbarWidth: "none",
                                msOverflowStyle: "none",
                            }}>
                                <div className="w-full p-0">
                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-[1em] gap-y-[0.5rem]">
                                        {isLoading ?
                                            <div className="flex items-center justify-center min-h-[40vh]"><CircularProgress size={60} color="inherit" /> </div> : <>

                                                {(itemsToShow && itemsToShow.length !== 0) ? itemsToShow.map((card, index) => (

                                                    <div key={card._id}
                                                        className="border-[1px] cursor-pointer border-[#D8D8D8] shadow-[0_12px_16px_#10182814] rounded-lg lg:p-[0.75em] w-[100%] lg:max-w-[50vw] bg-white flex flex-col"
                                                    >

                                                        <div className="w-full">
                                                            {selectedCardId === card._id ? (
                                                                <div>
                                                                    <div className="flex w-full justify-between">
                                                                        <h4 className="font-bold items-center md:text-[1.2rem] lg:w-[60rem] text-[1rem] w-[50%] py-[20px] pl-[0.5rem]">Do You Know</h4>
                                                                        {index < 6 && (
                                                                            <div className="flex justify-end items-center w-full py-2 px-2">
                                                                                <span className="text-[#FC1173] lg:text-[1rem] font-normal whitespace-nowrap">Recommended</span>
                                                                                {/* <img src={Star} alt="Star icon" className="w-[1rem]" /> */}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <h2 className="lg:text-[1rem] pl-[0.5em] font-inter text-[1rem] text-[#374151] font-[500]">
                                                                        {card?.doYouKnow}
                                                                    </h2>
                                                                    {/* <button onClick={() => handleBackClick()} className="text-blue-500 font-medium pl-[5px]">
                                                                    {(selectedCardId === card._id) ? 'Read less' : 'Read more'}
                                                                </button> */}
                                                                </div>
                                                            ) : (
                                                                index < 6 && (
                                                                    <div className="flex justify-end items-center w-full py-2  px-2">
                                                                        <span className="text-[#FC1173] lg:text-[1rem] font-normal">Recommended</span>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>

                                                        <div className="flex flex-col pl-[0.5em] flex-grow " onClick={() => handleAutoMation(card)}>
                                                            {selectedCardId === card._id ? (
                                                                <div className="mt-[1rem]">
                                                                    <span className="lg:text-[1rem] text-[0.65625rem]">{card?.know_More_heading}</span>
                                                                    <span className="lg:text-[1rem] text-[0.65625rem]">{card?.know_More_Subheading}</span>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <h2 className="lg:text-[1.8rem] font-inter text-[1.8rem] leading-35 ">{card?.question}</h2>
                                                                    {/* <h2 className="font-bold lg:text-[1.2rem] font-inter text-[1.2rem] mt-6 ">Do You Know ?</h2> */}
                                                                    <div className="flex mt-6 align-center">
                                                                        <h2 className="font-bold lg:text-[1.2rem] font-inter text-[1.2rem] ">
                                                                        </h2>
                                                                        <HeadsetMicOutlinedIcon /> {card.assisted} Assisted
                                                                    </div>
                                                                    <div>
                                                                        <p className="lg:text-[1rem] font-inter text-[1rem] text-[#374151] font-[500]"
                                                                            style={selectedCardId === card._id ? {} : {
                                                                                display: '-webkit-box',
                                                                                WebkitBoxOrient: 'vertical',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                WebkitLineClamp: 2,
                                                                            }}>
                                                                            {card?.doYouKnow}
                                                                        </p>


                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>

                                                        <div className="flex justify-between items-center p-2">
                                                            <div className="w-[100%]">
                                                                {selectedCardId === card._id ? (
                                                                    <div className="lg:w-[100%] w-full flex justify-end">
                                                                        <button
                                                                            onClick={() => handleAddToCart(card)}
                                                                            className={`flex justify-center items-center  w-[115px] lg:text-[0.775rem] text-[0.65625rem] rounded-lg px-[0.75rem] py-[0.52em] ${isItemInCart(card._id) ? 'text-black ' : ' text-black'}`}
                                                                        >
                                                                            {isItemInCart(card._id) ? (
                                                                                <>
                                                                                    <BookmarkAddedIcon className="mr-1" />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <BookmarkAddOutlinedIcon className="mr-1" />
                                                                                </>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <div className="lg:w-[100%] w-full flex justify-end">
                                                                        <button
                                                                            onClick={() => handleAddToCart(card)}
                                                                            className={`flex justify-center items-center  w-[115px] lg:text-[0.775rem]  text-[0.65625rem] rounded-lg px-[0.75rem] py-[0.52em] ${isItemInCart(card._id) ? ' text-black ' : ' text-black'}`}
                                                                        >
                                                                            {isItemInCart(card._id) ? (
                                                                                <>
                                                                                    <BookmarkAddedIcon className="mr-1" />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <BookmarkAddOutlinedIcon className="mr-1" />
                                                                                </>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                )) : (
                                                    <div className="border-[1px] border-[#D8D8D8] shadow-[0_12px_16px_#10182814] rounded-lg lg:p-[0.75em] w-[100%] lg:max-w-[50vw] bg-white flex flex-col">
                                                        <div className="flex w-full">No queries found, please change your filter criteria </div>
                                                    </div>
                                                )}
                                            </>}

                                    </div>
                                </div>

                                {
                                    itemsToShow && itemsToShow.length !== 0 && (
                                        <div className="w-full sticky bottom-[0]">

                                            <div className="w-full flex lg:hidden justify-center items-center mt-10">
                                                <button
                                                    style={{
                                                        backgroundColor: cartItemCount <= 0 ? "#dadada" : "rgb(4 81 114)",
                                                        padding: "5px 7px",
                                                        borderRadius: "7px",
                                                        color: "#fff",
                                                        width: "max-content",
                                                        position: "relative",
                                                    }}
                                                    onClick={handleGetAnswerClick}
                                                >
                                                    <BookmarksOutlinedIcon className="mr-1" />
                                                    My List
                                                    <span className="get-answer-badge">{cartItemCount}</span>
                                                </button>
                                            </div>
                                        </div>)}
                                {
                                    (itemsToShow.length !== 0 && !isLoading) &&
                                    <div className="w-full mt-7 mb-[45px] md:mb-2 justify-center align-center flex">
                                        <button className="md:w-[20%] w-full bg-[#fff] rounded-lg font-normal py-3 text-[#41426e] border-[1px] border-[#41426e]" onClick={() => handleExploreMoreClick()} >{exploreMore ? "Load Less" : "Load More"}</button>
                                    </div>
                                }


                            </div>
                        </div>
                        {openCategory && (

                            <MobileSidebarLayout handleCloseCategory={handleCloseCategory} handleCollapse={handleCollapse} setSearchText={setSearchText} filters={filters} isCollapse={isCollapse} />
                        )}

                        {isMobile && (
                            <QueryPageMobileMenu
                                activeSection={activeSection}
                                handleBottomNavigationChange={handleBottomNavigationChange}
                                handleFindForMe={handleFindForMe}
                                handleFilter={handleFilter}
                                handleGetAnswerClick={handleGetAnswerClick}
                                cartItemCount={cartItemCount}
                            />
                        )}
                    </>}
        </div>
    )
};

export default SixthQuery;
