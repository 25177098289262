import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation,useParams } from "react-router-dom";
import { motion } from 'framer-motion';
import QueryTemplate from "./QueryTemplate";
import Navbar from "../Navbar";
import { updateCartFromSession, emptyCart } from '../../Slices/askQuerySlice'
import { useMediaQuery } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';

const QueryEight = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {slug} = useParams();

    const [assistance, setAssistance] = useState(false);
    const { cart } = useSelector((state) => state.askQuery);
    const [highlightedQuery, setHighlightedQuery] = useState(cart.length > 0 ? cart[0] : null);
    const [activeSection, setActiveSection] = useState('answer');
    const [isTalkToExpert, setIsTalkToExpert] = useState(false);
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));


    // console.log("highlightedQuery",highlightedQuery)
    useEffect(() => {
        const sessionCart = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : [];
        if (cart.length > 0) {
            const newItems = cart.filter(
                (currentItem) => !sessionCart.some((sessionItem) => sessionItem._id === currentItem._id)
            );
            if (newItems.length > 0) {
                const updatedSessionCart = [...newItems, ...sessionCart]; //new Item in the beginning
                sessionStorage.setItem("cart", JSON.stringify(updatedSessionCart));
            }
        } else if (sessionCart.length > 0) {
            dispatch(updateCartFromSession(sessionCart));
        }
    }, [cart, dispatch]);

    useEffect(() => {
        const sessionCart = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : [];
        if (sessionCart[0]) {
            setHighlightedQuery(sessionCart[0])
            // const encodedQuestion = encodeURIComponent(sessionCart[0]?.question);
            // const encodedId = encodeURIComponent(sessionCart[0]?._id);
            // navigate(`?question=${encodedQuestion}&id=${encodedId}`);
            // console.log("higlitedQuery",sessionCart[0])

            const dynamicUrl=sessionCart[0]?.url;
            navigate(`/queries/${dynamicUrl}`);
        }

    }, [cart])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const idFromQuery = queryParams.get("id");
        const question = queryParams.get("question");

        const query = cart.filter(item => item.url === slug)
        if (slug && query) {
            setHighlightedQuery(...query);
        }
    }, [location]);

    const handleClick = (query) => {
        if (query !== highlightedQuery) {
            setHighlightedQuery(query);
            setActiveSection("answer")
            // const encodedQuestion = encodeURIComponent(query.question);
            // const encodedId = encodeURIComponent(query._id);
            // navigate(`?question=${encodedQuestion}&id=${encodedId}`);
            const dynamicUrl=query.url;
            navigate(`/queries/${dynamicUrl}`);
        }
    };

    const handleAddMoreQueries = () => {
        dispatch(emptyCart())
        navigate("/5")
    }

    const handleTalkToExprt = () => {
        setIsTalkToExpert(prev => !prev);
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}
        >
            <Navbar />
            <div className="w-full min-h-screen flex justify-center">
                <section
                    className={`${'md:ml-[5vw] w-[120vw] mt-[2rem] h-full flex gap-[2rem] flex-col sm:flex-row'}`}
                >
                    {(activeSection === 'myQueries' || !isMobile) && (
                        <aside
                            className={`w-full sm:w-[25vw] lg:w-[18vw] sm:min-h-[80vh] h-fit-content border-richgray-25 bg-[#F8F8F8] rounded-lg flex justify-center transition-all duration-300`} style={{ boxShadow: "0px 4px 4px 0px #00000040", boxShadow: "0px 4px 4px 0px #00000040" }} >
                            <div className="w-full sm:w-[25vw] lg:w-[20vw] p-[1.5rem]">
                                <button
                                    className="flex  border-[1px] px-2 py-[10px] border-[#20b486]  rounded-lg text-[#20b486] lg:text-[1.2rem] text-[1rem] items-center md:justify-left justify-center whitespace-nowrap w-full cursor-pointer px-[10px]"
                                    onClick={() => handleAddMoreQueries()}
                                >

                                    <PlaylistAddOutlinedIcon className="text-[#20b486] w-[1.875rem] mr-1" />
                                    Add more to List
                                </button>

                                <div className="w-full flex flex-col justify-evenly box-border gap-[0.6rem] mt-[0.6rem]">
                                    {cart.map((query, index) => (
                                        <div
                                            style={{
                                                boxShadow: "0px 0.25rem 0.5rem -0.125rem #10182808",
                                                boxShadow: "0px 0.75rem 1rem -0.25rem #10182814"
                                            }}
                                            key={index}
                                            className={`p-[0.5rem] cursor-pointer rounded-lg flex items-center justify-around ${highlightedQuery && highlightedQuery._id === query._id
                                                ? "bg-[#3749A6] text-white"
                                                : "bg-[#FFFFFF]"
                                                }`}
                                            onClick={() => handleClick(query)}
                                        >
                                            <p className="lg:text-[1rem] font-bold max-w-[12rem]"
                                                style={{
                                                    display: '-webkit-box',
                                                    WebkitBoxOrient: 'vertical',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    WebkitLineClamp: 2,
                                                    // whiteSpace: 'nowrap'
                                                }}>
                                                {query.question}
                                            </p>
                                            <ChevronRightIcon className="w-[1.875rem]" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </aside>
                    )}
                    <QueryTemplate selectedId={highlightedQuery?._id} setAssistance={setAssistance} assistance={assistance} activeSection={activeSection} setIsTalkToExpert={setIsTalkToExpert} isTalkToExpert={isTalkToExpert} setActiveSection={setActiveSection} />
                </section>
            </div>
            {isMobile && (
                <div className="fixed bottom-0 left-0 w-full">
                    <button
                        onClick={handleTalkToExprt}
                        className="flex items-center justify-center w-full py-3 bg-[#0057e7] text-white font-bold"
                    >
                        <SupportAgentIcon style={{ marginRight: '8px' }} />
                        Talk to an expert
                    </button>
                </div>
            )}
        </motion.div>
    );
};

export default QueryEight;
